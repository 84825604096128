import Util from './util';
import MagicUI from './magicUI';
import { EventManager } from '@lindar-joy/goosicorn-event-emitter';
import DFGUI from './dfg';
import Connector from './connector';
import { showErrorDialog } from './errorHandling';
import Wallet from './wallet';
import GameHistory from './gameHistory';

export { Util };

(window as any).MagicUI = MagicUI;
(window as any).DFGUI = DFGUI;
(window as any).Connector = Connector;
(window as any).Util = Util;
(window as any).EventManager = EventManager;
(window as any).ShowErrorDialog = showErrorDialog;
(window as any).Wallet = Wallet;
(window as any).GameHistory = GameHistory;
