export function AnimateFromTo(
    element: HTMLElement,
    xA: number,
    yA: number,
    xB: number,
    yB: number,
    time: number,
    out = false,
) {
    let t = 0;
    let accum = 0;
    let last: number;
    let x: number;
    let y: number;
    let delta = 0;
    const animate = (timeStamp: number) => {
        if (last === undefined) {
            last = timeStamp;
        }

        delta = timeStamp - last;
        accum += delta;
        t = accum / time;

        if (accum >= time) {
            t = 1;
        }

        if (xA < xB) {
            x = -(element.clientWidth / 2) + (xA + (xB - xA) * t);
        } else {
            x = -(element.clientWidth / 2) + (xA + (xB - xA) * t);
        }

        if (yA < yB) {
            y = -(element.clientHeight / 2) + (yA + (yB - yA) * t);
        } else {
            y = -(element.clientHeight / 2) + (yA + (yB - yA) * t);
        }

        if (out) {
            element.style.transform = `translate(${Math.floor(x).toString()}px, ${Math.floor(
                y,
            ).toString()}px) scale(${1 - t})`;
            element.style.opacity = (1 - t).toString();
        } else {
            element.style.transform = `translate(${Math.floor(x).toString()}px, ${Math.floor(
                y,
            ).toString()}px) scale(${t})`;
            element.style.opacity = t.toString();
        }

        if (accum < time) {
            requestAnimationFrame(animate);
        }

        last = timeStamp;
    };

    requestAnimationFrame(animate);
}
