import Connector, { IConnectionConfig } from './connector';

module GDK {
    export function getConnectionData(): IConnectionConfig {
        const url = window.location.href;
        const urlParams = new URL(url).searchParams;
        const data = {
            playerSessionId: urlParams.get('playerSessionId') || '',
            server: urlParams.get('server') || '',
            gameId: urlParams.get('gameId') || '',
            accountType: urlParams.get('accountType') || '',
        };
        if (!data.playerSessionId) {
            throw Error('Parameter [playerSessionId] not present in url: ' + window.location.href);
        }
        if (!data.server) {
            throw Error('Parameter [server] not present in url: ' + window.location.href);
        }
        if (!data.gameId) {
            throw Error('Parameter [gameId] not present in url: ' + window.location.href);
        }
        if (!data.accountType) {
            throw Error('Parameter [accountType] not present in url: ' + window.location.href);
        }
        console.debug('Connection data: ');
        console.debug({ data });
        return data;
    }

    export const init = async () => {
        console.debug('Initialising GDK...');
        const connectionData = getConnectionData();
        if (connectionData) {
            await Connector.connect(connectionData);
            return true;
        } else {
            console.error('Unable to initialise GDK, error found in connection data');
        }
        return false;
    };
}

export default GDK;
