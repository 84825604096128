import ls from 'localstorage-slim';

export class Storage {
    private gameID: string;
    private storage = {};

    constructor(gameID: string) {
        this.gameID = gameID;

        this.storage = ls.get(this.gameID) ?? {};
    }

    set<T = unknown>(key: string, value: T) {
        this.storage[key] = value;

        ls.set(this.gameID, this.storage);
    }

    get<T_1 = unknown>(key: string): T_1 | null {
        return (this.storage[key] as T_1) || null;
    }
}
