let consoleInUse: boolean = false;

namespace MagicConsole {
    let consoleInitialised: boolean = false;
    let consoleDiv: HTMLDivElement;
    let events: Array<any> = [];
    let toggles: Map<HTMLDivElement, any> = new Map();

    export function initialise() {
        if (!consoleInUse) {
            return;
        }
        const magicUIDiv = document.getElementById('magic-ui-div');
        consoleDiv = document.createElement('div');
        consoleDiv.id = 'magic-ui-console';
        magicUIDiv?.appendChild(consoleDiv);
        events.forEach((event) => {
            printEvent(event);
        });
        consoleInitialised = true;
        consoleDiv?.classList.add('hidden');
        document.addEventListener('keydown', (e) => {
            if (e.code === 'Backquote') {
                consoleDiv?.classList.toggle('hidden');
            }
        });
    }

    export function registerAction(event: any) {
        if (consoleInUse) {
            events.push(event);
        }

        if (consoleInitialised) {
            printEvent(event, true);
        }
    }

    export function registerEvent(event: any) {
        if (consoleInUse) {
            events.push(event);
        }

        if (consoleInitialised) {
            printEvent(event);
        }
    }

    function toggle(arrow: HTMLDivElement, div: HTMLDivElement, recursive = false) {
        if (recursive) {
            const collapsed = div.classList.contains('collapsed');
            const keys = div.querySelectorAll('.key');
            const keyValues = div.querySelectorAll('.key-value-container');

            keys.forEach((key) => {
                if (collapsed) {
                    key.classList.add('collapsed');
                } else {
                    key.classList.remove('collapsed');
                }
            });

            keyValues.forEach((keyValue) => {
                if (collapsed) {
                    keyValue.classList.add('collapsed');
                } else {
                    keyValue.classList.remove('collapsed');
                }
            });
        } else {
            div.classList.toggle('collapsed');
            arrow.classList.toggle('collapsed');
        }
    }

    export function printEvent(event: any, isAction = false) {
        const div = document.createElement('div');
        div.style.pointerEvents = 'all';
        const root = document.createElement('div');
        const collapse = document.createElement('div');
        const children = document.createElement('div');
        children.classList.add('children');
        children.classList.add('collapsed');
        collapse.classList.add('collapse', 'collapsed');
        root.onclick = (e) => {
            e.preventDefault();
            e.stopPropagation();
            toggle(collapse, children);
        };

        collapse.innerText = '▼';
        collapse.onclick = () => {
            toggle(collapse, children);
        };
        const h = new Date().getHours();
        const m = new Date().getMinutes();
        const s = new Date().getSeconds();
        const time = `${h.toString().padStart(2, '0')}:${m.toString().padStart(2, '0')}:${s
            .toString()
            .padStart(2, '0')}`;
        if (isAction) {
            root.innerHTML = `<span style="color: #9eb8ff">${event.type} <span style="color: white">(${time})</span></span>`;
        } else {
            root.innerHTML = `<span style="color: grey">${
                event.type.split(':')[0]
            }</span>:<span style="color: #60df60">${
                event.type.split(':')[1]
            } <span style="color: white">(${time})</span></span>`;
        }

        root.style.cursor = 'pointer';
        div.appendChild(collapse);
        div.appendChild(root);
        div.appendChild(children);
        console.log(event);
        const keys = Object.keys(event);
        keys.sort();
        for (let key of keys) {
            if (key === 'type') {
                continue;
            }
            const child = createKeyDiv(key, event[key], true);
            children.appendChild(child);
        }
        div.classList.add('console-entry');

        consoleDiv.appendChild(div);

        consoleDiv.scrollTop = consoleDiv.scrollHeight;
    }

    export function createKeyDiv(key: string, value: any, collapsed = false) {
        const div = document.createElement('div');
        const children = document.createElement('div');
        const clip = document.createElement('div');
        clip.classList.add('clip');
        clip.innerHTML = '&nbsp;(copy)';
        clip.style.color = 'grey';
        clip.style.visibility = 'hidden';
        clip.style.cursor = 'pointer';

        clip.onclick = (e) => {
            navigator.clipboard.writeText(JSON.stringify(value, null, '\t'));
            e.stopPropagation();
        };

        children.classList.add('children');
        div.style.marginLeft = '20px';
        div.classList.add('key-value-container');
        if (collapsed) {
            children.classList.add('collapsed');
        }
        if (typeof value !== 'object') {
            switch (typeof value) {
                case 'string':
                    div.innerHTML = `<span class="key" style="float: left">${key}: <span style="color: #f59066">"${value}"</span></span>`;
                    break;
                case 'number':
                    div.innerHTML = `<span class="key" style="float: left">${key}: <span style="color: #99bfff">${value}</span></span>`;
                    break;
            }
            div.appendChild(clip);
            div.style.paddingLeft = '12px';
        } else {
            const root = document.createElement('div');
            root.classList.add('key-value-container');
            if (value && Object.keys(value).length > 0) {
                const collapse = document.createElement('div');
                collapse.classList.add('collapse', 'collapsed');
                collapse.innerText = '▼';
                root.onclick = (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    toggle(collapse, children);
                };
                collapse.onclick = () => {
                    toggle(collapse, children);
                };
                div.appendChild(collapse);
                div.style.cursor = 'pointer';
            } else {
                div.style.paddingLeft = '12px';
                root.style.color = '#595959';
            }

            div.appendChild(root);
            div.appendChild(children);
            if (Array.isArray(value)) {
                root.innerHTML = `<span class="key" style="float:left;">${key}[${value.length}]</span>`;
            } else {
                root.innerHTML = `<span class="key" style="float:left;">${key}</span>`;
            }
            root.appendChild(clip);
            for (let key in value) {
                children.appendChild(createKeyDiv(key, value[key], true));
            }
        }

        return div;
    }
}

const url = window.location.href;
const urlParams = new URL(url).searchParams;
consoleInUse = urlParams.has('console') && urlParams.get('console') !== 'false';

export default MagicConsole;
