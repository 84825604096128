import { IBalanceEvent } from '@lindar-joy/goosicorn-event-emitter';
import Connector from './connector';

namespace Wallet {
    let balances: IBalance[] = [];
    export const parseMagicWalletBalance = (magicWalletBalance: string, value): IBalance => {
        const quantity = value;
        const splitKey = magicWalletBalance.split(':');
        const type = splitKey[0];
        const gameId = splitKey[1];
        const denomination = parseInt(splitKey[2]);
        const currency = splitKey[3];
        const batchId = splitKey[4];
        return {
            type,
            denomination,
            quantity,
            gameId,
            currency,
            bonusString: magicWalletBalance,
            batchId,
        };
    };

    export const parse = async (balanceEvent: IBalanceEvent) => {
        balances = [];
        for (const [balance, value] of Object.entries(balanceEvent.magicWalletBalance)) {
            balances.push(parseMagicWalletBalance(balance, value));
        }
    };

    export const getBonusFreeSpinsByTemplateId = (templateId: string) => {
        return balances.find((balance) => balance.batchId === templateId);
    };

    export const getBonusFreeSpinsAtDenomination = (denomination: number) => {
        const gameId = Connector.getGameId();
        return balances
            .filter((balance) => balance.gameId === gameId)
            .filter((balance) => balance.denomination === denomination)
            .filter((balance) => balance.consumed === false || balance.quantity > 0)[0];
    };

    export const updateBonusFreeSpinsConsumed = (templateId: string, consumed: boolean) => {
        balances.forEach((balance) => {
            if (balance.batchId === templateId) {
                balance.consumed = consumed;
            }
        });
    };

    export const getBonusFreeSpinsAvailable = () => {
        const gameId = Connector.getGameId();
        return balances
            .filter((balance) => balance.gameId === gameId)
            .filter((balance) => balance.consumed === false || balance.quantity > 0);
    };
}

export default Wallet;

interface IBalance {
    batchId: string;
    type: string;
    denomination: number;
    quantity: number;
    gameId: string;
    currency: string;
    bonusString: string;
    consumed?: boolean;
}
