import { IError } from '@lindar-joy/goosicorn-event-emitter';

interface IErrorConfig {
    title?: string;
    message?: string;
    reloadAvailable?: boolean;
    cashierAvailable?: boolean;
    closeGameAvailable?: boolean;
    dismissDialog?: boolean;
    suppress?: boolean;
    icon?: string;
}

const ERRORS: { [key: string]: IErrorConfig } = {
    BALANCE_ERROR: {
        title: 'Balance error',
        message: 'There was an error retrieving your balance. Reload to try again.',
        reloadAvailable: true,
        icon: 'general-error',
    },
    ACTIVATION_ERROR: {
        title: 'Activation error',
        message: 'There was an error activating your session. Reload to try again.',
        reloadAvailable: true,
        icon: 'general-error',
    },
    BET_ERROR: {
        title: 'Bet error',
        message: 'There was an error performing this bet. Reload to try again.',
        reloadAvailable: true,
        icon: 'general-error',
    },
    DFG_PRIZE_CONFIG_ERROR: {
        title: 'DFG prize error',
        message: 'There was an error retreiving the prizes for this game. Reload to try again.',
        reloadAvailable: true,
        icon: 'general-error',
    },
    DFG_TOKEN_ERROR: {
        title: 'DFG token error',
        message: 'There was an error assigning your DFG token. Reload to try again.',
        reloadAvailable: true,
        icon: 'general-error',
    },
    WALLET_ERROR: {
        title: 'Wallet error',
        message:
            'There was an error while trying to perform a wallet transaction. Reload to try again.',
        reloadAvailable: true,
        icon: 'general-error',
    },
    ACTION_ERROR: {
        title: 'Action error',
        message: 'There was an error while trying to perform this action. Reload to try again.',
        reloadAvailable: true,
        icon: 'general-error',
    },
    CONFIG_ERROR: {
        title: 'Config error',
        message: 'There was an error retrieving the config for this game. Reload to try again.',
        reloadAvailable: true,
        icon: 'general-error',
    },
    INSUFFICIENT_FUNDS_ERROR: {
        title: 'Insufficient funds',
        message: 'Your balance is too low to make this bet. Add funds to your account to continue.',
        reloadAvailable: false,
        cashierAvailable: true,
        closeGameAvailable: true,
        dismissDialog: true,
        icon: 'general-error',
    },
    STATE_ERROR: {
        title: 'State error',
        message: 'There was an error recovering the existing game state. Reload to try again.',
        reloadAvailable: true,
        icon: 'general-error',
    },
    WIN_ERROR: {
        title: 'Winnings error',
        message: 'There was an error retrieving your winnings. Reload to try again.',
        reloadAvailable: true,
        icon: 'general-error',
    },
    NEW_SESSION_ERROR: {
        title: 'Session ended',
        message: 'A new game session has been started elsewhere. Reload to try again.',
        closeGameAvailable: true,
        icon: 'general-error',
    },
    DFG_PROGRESS_SAVE_ERROR: {
        title: 'DFG progress error',
        message: 'An error was encountered while saving your points progress.',
        reloadAvailable: true,
        icon: 'general-error',
    },
    COMMS_ERROR: {
        title: 'Communications error',
        message: 'An invalid message was received.',
        reloadAvailable: true,
        icon: 'general-error',
    },
    WEBSOCKET_CLOSED: {
        title: 'Communications error',
        message: 'Reload to try again.',
        reloadAvailable: true,
        icon: 'general-error',
    },
    DFG_PLAYED_ERROR: {
        suppress: true,
    },
};

const ERROR_HANDLING: { [key: string]: IErrorConfig } = {
    MISSING_GAME_SESSION: ERRORS.ACTIVATION_ERROR,
    WEBSOCKET_CLOSED: ERRORS.WEBSOCKET_CLOSED,

    '0A7TPU29QM': ERRORS.DFG_PRIZE_CONFIG_ERROR,
    '0B2AL4QZBZ': ERRORS.WALLET_ERROR,
    '1MHUI0TH4G': ERRORS.WALLET_ERROR,
    '1UMZP5KX8U': ERRORS.WALLET_ERROR,
    '1VBMBWGZVI': ERRORS.BET_ERROR,
    '2DVUALBKF3': ERRORS.ACTION_ERROR,
    '30S6N8JE3V': ERRORS.ACTIVATION_ERROR,
    '3DMKY5YX4K': ERRORS.WALLET_ERROR,
    '3NR16NICB9': ERRORS.WALLET_ERROR,
    '5JL6TG26TF': ERRORS.ACTIVATION_ERROR,
    '5UNQPUZTD7': ERRORS.BET_ERROR,
    '5W5L3B1B7U': ERRORS.WALLET_ERROR,
    '6L78T3PA2G': ERRORS.CONFIG_ERROR,
    '6N8PBGMAG0': ERRORS.ACTIVATION_ERROR,
    '6NLAJSOS1I': ERRORS.NEW_SESSION_ERROR,
    '6ONCH4GK1N': ERRORS.INSUFFICIENT_FUNDS_ERROR,
    '6P2TUP4P6C': ERRORS.ACTION_ERROR,
    '749GTJCFY0': ERRORS.ACTION_ERROR,
    '7K2LMQLRFJ': ERRORS.STATE_ERROR,
    '7LHPL1C4FM': ERRORS.DFG_TOKEN_ERROR,
    '803UIGANII': ERRORS.WIN_ERROR,
    '95JWJA9J6D': ERRORS.NEW_SESSION_ERROR,
    '97IFPD9AX8': ERRORS.ACTIVATION_ERROR,
    AE1GZZ98JS: ERRORS.DFG_PROGRESS_SAVE_ERROR,
    B4KRGHE9JQ: ERRORS.ACTIVATION_ERROR,
    BN75D618OP: ERRORS.WALLET_ERROR,
    BS6LELVASX: ERRORS.CONFIG_ERROR,
    C31AE51ZXO: ERRORS.ACTIVATION_ERROR,
    DBYG5YCGMF: ERRORS.BET_ERROR,
    DQ3FNZD28Z: ERRORS.BET_ERROR,
    E1NJ3G7Z7U: ERRORS.NEW_SESSION_ERROR,
    E52FHOYDR2: ERRORS.DFG_TOKEN_ERROR,
    EWWFLPWNQR: ERRORS.INSUFFICIENT_FUNDS_ERROR,
    FCUSEM4N4M: ERRORS.WALLET_ERROR,
    FP238FKXX2: ERRORS.BET_ERROR,
    ZUA6SKXM51: ERRORS.BET_ERROR,
    FUVGMK2VYR: ERRORS.WALLET_ERROR,
    FX7J4BD1HW: ERRORS.WALLET_ERROR,
    GGY10V0STE: ERRORS.WALLET_ERROR,
    GGZI83PL04: ERRORS.NEW_SESSION_ERROR,
    GPRECPTOLF: ERRORS.ACTIVATION_ERROR,
    H1LBC6SWVT: ERRORS.BALANCE_ERROR,
    H462YL13CE: ERRORS.BET_ERROR,
    I7O57BRFMH: ERRORS.BET_ERROR,
    ICQQKKGDZA: ERRORS.BET_ERROR,
    J0KVGV4MIJ: ERRORS.WALLET_ERROR,
    JB88K7NBTP: ERRORS.ACTIVATION_ERROR,
    K2VWY55JCH: ERRORS.BET_ERROR,
    KEKR6OJ322: ERRORS.WALLET_ERROR,
    L1CPGA3OVU: ERRORS.WALLET_ERROR,
    LE1FIX73UA: ERRORS.BET_ERROR,
    LLBR40WW0H: ERRORS.WALLET_ERROR,
    M2B28768NH: ERRORS.WALLET_ERROR,
    MI67AD156Z: ERRORS.WALLET_ERROR,
    OOUFJW67P3: ERRORS.WALLET_ERROR,
    OU1OROM7Q9: ERRORS.WALLET_ERROR,
    OYCALHHZC5: ERRORS.BET_ERROR,
    P2BNUDA03E: ERRORS.INSUFFICIENT_FUNDS_ERROR,
    PVWGC4B7KP: ERRORS.WALLET_ERROR,
    QQ4B4GPJ65: ERRORS.INSUFFICIENT_FUNDS_ERROR,
    QVOR54JO3S: ERRORS.BET_ERROR,
    QWWJCM41OS: ERRORS.WALLET_ERROR,
    QZW5QQEUTU: ERRORS.WALLET_ERROR,
    R6UU9IYXPW: ERRORS.COMMS_ERROR,
    RZP21AHJ0F: ERRORS.WALLET_ERROR,
    SP9VOX94DH: ERRORS.WALLET_ERROR,
    STUZ9OF8PN: ERRORS.WALLET_ERROR,
    TFJ7Q6ZCVE: ERRORS.WALLET_ERROR,
    TTFG7DRK2Y: ERRORS.WALLET_ERROR,
    U9VYYM7HOL: ERRORS.WALLET_ERROR,
    UOOTAEV78W: ERRORS.WALLET_ERROR,
    W6BLI70J7D: ERRORS.DFG_PLAYED_ERROR,
    XA1TXHXK23: ERRORS.BET_ERROR,
    XUYSRJ25EC: ERRORS.DFG_TOKEN_ERROR,
    XVK1UGL2BR: ERRORS.BET_ERROR,
    YDSNXIK1UN: ERRORS.WALLET_ERROR,
    YW1DB12OWM: ERRORS.COMMS_ERROR,
    Z1ZPFT6RZT: ERRORS.WALLET_ERROR,
};

export const showErrorDialog = (error: IError) => {
    console.error(error);
    if (error.errorCode === 'W6BLI70J7D') {
        return;
    }
    const magicUIDiv = document.getElementById('magic-ui-div');
    if (magicUIDiv) {
        magicUIDiv.classList.remove('hidden');
        magicUIDiv.style.display = '';
    }

    const errorConfig = ERROR_HANDLING[error.errorCode];
    const mainUI = document.getElementById('main-ui');
    if (mainUI) {
        mainUI.classList.remove('hidden');
    }
    if (errorConfig) {
        if (!errorConfig.reloadAvailable) {
            const errorReload = document.getElementById('error-reload');
            if (errorReload) {
                errorReload.style.display = 'none';
            }
        }

        const errorCloseButton = document.getElementById('error-close');

        if ((errorConfig.closeGameAvailable || errorConfig.dismissDialog) && errorCloseButton) {
            if (errorConfig.dismissDialog) {
                errorCloseButton.removeAttribute('onClick');
                errorCloseButton.onclick = () => {
                    document.getElementById('general-error-dialog')?.classList.add('hidden');
                    document.getElementById('backlay')?.classList.add('hidden');
                    document.getElementById('error-overlay')?.classList.add('hidden');
                };
            }
        } else if (errorCloseButton) {
            errorCloseButton.style.display = 'none';
        }

        const errorTitleElement = document.getElementById('general-error-title');
        if (errorTitleElement && errorConfig.title) {
            errorTitleElement.innerText = errorConfig.title;
        }

        const errorMessageElement = document.getElementById('general-error-message');
        if (errorMessageElement && errorConfig.message) {
            errorMessageElement.innerText = errorConfig.message;
        }

        const errorIconElement = document.getElementById('general-error-icon');
        if (errorIconElement && errorConfig.icon) {
            errorIconElement.classList.add(errorConfig.icon);
        }
    }

    document.getElementById('general-error-dialog')?.classList.remove('hidden');
    document.getElementById('error-overlay')?.classList.remove('hidden');

    const errorCodeElement = document.getElementById('general-error-code');
    if (errorCodeElement) {
        errorCodeElement.innerText = `Error: ${error.errorCode}`;
    }

    document.getElementById('backlay')?.classList.remove('hidden');
};
